import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -0.3%;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
`
