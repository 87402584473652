import ComplainHere from 'src/components/common/Institutional/ComplainHere'
import Newsletter from 'src/components/sections/Newsletter'

const ReclameAqui: FCC = () => {
  return (
    <>
      <ComplainHere />
      <Newsletter />
    </>
  )
}

export default ReclameAqui
