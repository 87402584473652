import { CustomLink } from '../../CustomLink'
import * as S from './styles'

const ComplainHere: FCC = () => {
  return (
    <S.Container>
      <CustomLink to="/">
        <img
          src="https://miess.vtexassets.com/arquivos/reclame_aqui_01.jpg"
          alt="Banner compre já"
        />
      </CustomLink>
      <CustomLink to="/">
        <img
          src="https://miess.vtexassets.com/arquivos/reclame_aqui_02.jpg"
          alt="Banner compre já"
        />
      </CustomLink>
      <CustomLink to="/">
        <img
          src="https://miess.vtexassets.com/arquivos/reclame_aqui_03.jpg"
          alt="Banner compre já"
        />
      </CustomLink>
    </S.Container>
  )
}

export default ComplainHere
